@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectOption[data-v-41af2202] {
  display: flex;
  align-items: center;
  min-height: 40px;
  box-sizing: border-box;
  padding:  0.5rem;
  margin:  0;
  text-align: left;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #313233;
  cursor: pointer;
}
.MultiSelectOption[data-v-41af2202]:hover {
    background-color: #EDF7FB;
}
.MultiSelectOption.is-disabled[data-v-41af2202] {
    color: #929699;
}
.MultiSelectOption.is-disabled[data-v-41af2202]:hover {
      background-color: #FFFFFF;
      cursor: not-allowed;
}
.MultiSelectOption .MultiSelectOptionIcon[data-v-41af2202] {
    margin:  0 0.5rem 0 0;
    font-size: 1rem;
}

@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectGroupLabel[data-v-fd394520] {
  display: flex;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding:  0 0.5rem;
  margin:  0;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #C4C9CC;
}

@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectSearchBoxWrapper[data-v-7618172e] {
  position: relative;
  margin:  0 0.5rem 0.5rem;
  padding:  0.5rem 0;
  border-bottom: 1px solid #DCE1E5;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e] {
    width: 100%;
    height: 40px;
    padding:  0 0.5rem;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #929699;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    appearance: none;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox.is-empty[data-v-7618172e] {
      background: #EDF7FB;
      border: 0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox.is-empty[data-v-7618172e]:focus {
        border: 0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e]:focus {
      outline: none;
      border: 1px solid #247BA0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBox[data-v-7618172e]::placeholder {
      color: #247BA0;
}
.MultiSelectSearchBoxWrapper .MultiSelectSearchBoxIcon[data-v-7618172e] {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}

@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectSelectedOption[data-v-49d565fa] {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  padding:  0 2rem 0 0.5rem;
  margin:  0 0.5rem 0.5rem 0;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: #313233;
  background: #EDF7FB;
  border-radius: 15px;
  border: 1px solid #EDF7FB;
}
.MultiSelectSelectedOption[data-v-49d565fa]:hover {
    cursor: pointer;
    text-decoration: line-through;
    border: 1px solid #929699;
}
.MultiSelectSelectedOption[data-v-49d565fa]:focus {
    outline: none;
}
.MultiSelectSelectedOptionDelete[data-v-49d565fa] {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin:  0 0 0 0.5rem;
  cursor: pointer;
}

@charset "UTF-8";
/* Primary palette. */
/* Grey palette. */
/* Secondary palette. */
/* Use this to reset items or place them at the bottom. */
/* Use this for creating a new stacking context within an element. */
/* Middle level for specific tweaking. */
/* Header, Tooltips, Fixed elements. */
/* Overlay for highlighting elements, Cookie wall. */
/* Full page overlays and it’s contents, Highlighted elements. */
/**
 * Apply the content that is between the brackets of the mixin usage only for the
 * breakpoint(s) that are included in the arguments.
 * @param {...String} $Arguments
 */
/**
 * Get font size and corresponding line-height for preset sizes.
 * @param {String} $Size
 */
/**
 * Get heading size and corresponding breakpoint-dependant line-height for preset
 * sizes.
 * @param {String} $Size
 */
/**
 * Get the areas values in rems based on four the dimentions,
 * and the maximum amout of dimentions is four.
 * @param {list} $Args [0]
 * @return {number | list}
 */
/**
 * @param {list} $args [0]
 */
/**
 * @param {list} $args [0]
 */
.MultiSelectWrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding:  0 0.5rem;
  border-radius: 3px;
  border: 1px solid #DCE1E5;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.MultiSelectWrapper:focus {
    border-color: #247BA0;
    outline: none;
}
.MultiSelectWrapper.is-invalid {
    border: 1px solid #C75146;
}
.MultiSelectWrapper.is-disabled {
    background-color: #E8ECED;
    border: 0;
}
.MultiSelectWrapper.is-disabled .MultiSelectIcon {
      border-top: 8px solid #C4C9CC;
}
.MultiSelectWrapper.is-disabled .MultiSelectLabel {
      cursor: not-allowed;
}
.MultiSelectWrapper .MultiSelectIcon {
    position: absolute;
    right: 1rem;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #313233;
    transform: translateY(-50%);
    transition: 0.5s all;
}
.MultiSelectWrapper .MultiSelectIcon.is-active {
      transform: rotate3d(1, 0, 0, 180deg) translateY(calc(-50% + 8px));
}
.MultiSelectLabel {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  cursor: pointer;
}
.MultiSelectLabel.is-empty {
    color: #C4C9CC;
}
.MultiSelectLabel .MultiSelectPlaceholder {
    position: absolute;
    left: 0.25rem;
    max-width: calc(100% - 2rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}
.MultiSelectLabel .FloatingLabel {
    padding: 0 0.25rem;
    transition: all 0.3s;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: #C4C9CC;
    cursor: pointer;
}
.MultiSelectLabel .FloatingLabel.is-active {
      transform: translateY(-24px);
      font-size: 0.8125rem;
      line-height: 1rem;
      background: linear-gradient(0deg, #FFFFFF 50%, transparent 50%);
      cursor: default;
}
.MultiSelectDropDown {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  z-index: 50;
  min-width: 100%;
  max-height: 300px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #DCE1E5;
  border-radius: 3px;
  background-color: #FFFFFF;
}
.MultiSelectDropDown ol {
    list-style-type: none;
    margin:  0;
    padding:  0;
}
.MultiSelectSelectedOptions {
  width: 100%;
  padding:  0 1.5rem 0 0;
  box-sizing: border-box;
  text-align: left;
}
.ValidationLabel,
.HelperMessage {
  padding: 0.5rem 0 0.5rem 0.75rem;
  color: #C4C9CC;
  font-size: 0.8125rem;
  line-height: 1rem;
  text-align: left;
}
.ValidationLabel {
  color: #C75146;
}


